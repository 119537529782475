var slideIndex = 0;

window.onload = (event) => {
    const clickButtonnext = document.getElementById("next");
    const clickButtonprev = document.getElementById("prev");
    clickButtonnext.addEventListener('click', (event) => {
        plusSlides(1);
        showSlides();
    });
    clickButtonprev.addEventListener('click', (event) => {
        plusSlides(-1);
        showSlides();
    });
};
function plusSlides(n) {
    slideIndex += n;
    showSlides();
}
function showSlides() {
    var slides = document.getElementsByClassName("News_entry");
    if (slideIndex + 1 > slides.length) {
        slideIndex = 0;
    }
    else if (slideIndex < 0) {
        slideIndex = slides.length - 1;
    }
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }
    slides[slideIndex].style.display = "block";
}
